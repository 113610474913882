import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

export const Heading = styled.h2`
	font-size: 34px;
	font-weight: 800;
	text-align: center;
	margin: 0 0 ${props => (props.hero ? '60px' : '35px')} 0;
	padding: 0 10px;
	color: #000;
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.tablet}px) {
		font-size: 40px;
	}
`;

export const Wrapper = styled.section`
	padding: 35px 0;
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.tablet}px) {
		padding: 40px 0;
	}
	position: relative;
	.background-mask {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		padding: 0 16px;
		z-index: 0;
		.background-mask__grid {
			max-width: 1186px;
			height: 100%;
			margin: 0 auto;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 1fr;
			height: 100%;
			.background-mask__grid__item {
				background: linear-gradient(
					180deg,
					rgba(66, 71, 112, 0.09),
					rgba(66, 71, 112, 0.09) 50%,
					transparent 0,
					transparent
				);
				background-size: 1px 8px;
				width: 1px;
			}
		}
	}
`;

export const Description = styled.p`
	text-align: center;
	font-size: 25px;
	font-weight: 400;
	line-height: 1.5;
	max-width: 980px;
	margin: 0 auto 20px;
	padding: 0 10px;
	color: #333;

	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		margin-bottom: 30px;
	}
`;

const StyledAL = css`
	align-items: center;
	border: 0;
	border-radius: 4px;
	box-shadow: none;
	cursor: pointer;
	display: inline-flex;
	height: 44px;
	font-size: 16px;
	font-weight: bold;
	justify-content: center;
	letter-spacing: 0.25px;
	min-width: auto;
	outline: none;
	padding: 0 24px;
	position: relative;
	text-decoration: none;
	text-transform: none;
	transition: none;
	vertical-align: inherit;
	margin: 8px;
	white-space: pre;

	&:disabled,
	&:disabled:hover,
	&:disabled:focus {
		background-color: #dadce0;
		border-color: #dadce0;
		color: #fff;
		cursor: default;
	}

	&:active {
		-webkit-box-shadow: none;
		box-shadow: none;
	}

	&:focus {
		text-decoration: none;
	}

	&:hover {
		text-decoration: none;
	}

	&.hostjane-button--primary {
		background-color: #1a73e8;
		color: #fff;
	}

	&.hostjane-button--primary:hover {
		background-color: #174ea6;
		border: 0;
	}

	&.hostjane-button--primary:active,
	&.hostjane-button--primary:focus {
		background-color: #1a73e8;
		border: 0;
	}

	&.hostjane-button--primary:active::before,
	&.hostjane-button--primary:focus::before {
		background-color: #fff;
		content: '';
		height: 100%;
		left: 0;
		opacity: 0.24;
		position: absolute;
		width: 100%;
	}

	&.hostjane-button--primary.hostjane-button--reversed {
		background-color: #fff;
		color: #000;
	}

	&.hostjane-button--primary.hostjane-button--reversed:hover,
	&.hostjane-button--primary.hostjane-button--reversed:focus {
		background-color: rgba(255, 255, 255, 0.7);
	}

	&.hostjane-button--secondary {
		background-color: #fff;
		border: 1px solid #dadce0;
		color: #1a73e8;
		padding: 0 23px;
	}

	&.hostjane-button--secondary:active,
	&.hostjane-button--secondary:focus,
	&.hostjane-button--secondary:hover {
		border-color: #d2e3fc;
	}

	&.hostjane-button--secondary:hover {
		background-color: #eff5fd;
	}

	&.hostjane-button--secondary:active,
	&.hostjane-button--secondary:focus {
		background-color: #d8e7fb;
	}
`;

export const StyledAnchor = styled.a`
	${StyledAL}
`;

export const StyledLink = styled(Link)`
	${StyledAL}
`;
