import styled from 'styled-components';

export default styled.div`
	padding: 0 10px;
	margin: 0;

	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.desktop}px) {
		padding: 0 20px;
		max-width: ${props => props.theme.siteApp.maxWidth}px;
		margin: 0 auto;
	}
`;
