const sectionOne = {
	title: 'HostJane Site Map',
	columns: {
		// column one
		one: [
			// list one
			{
				title: 'Freelance Services Marketplace',
				links: [
					{
						title: 'Web / Mobile / Tech',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers',
					},
					{
						title: 'Design / Art / Video / Audio',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice',
					},
					{
						title: 'Bookings',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors',
					},
					{
						title: 'Writing / Translation',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers',
					},
					{
						title: 'Business',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support',
					},
				],
			},
			// list two
			{
				title: 'VPS & Cloud Hosting',
				links: [
					{
						title: 'Hosting Homepage',
						link: 'https://cloud.hostjane.com/',
					},
					{
						title: 'VPS for Business',
						link: 'https://cloud.hostjane.com/vps-hosting/',
					},
					{
						title: 'WordPress Hosting',
						link: 'https://cloud.hostjane.com/wordpress',
					},
					{
						title: 'Cloud Hosting',
						link: 'https://cloud.hostjane.com/cloud',
					},
					{
						title: 'Windows Hosting',
						link: 'https://cloud.hostjane.com/windows',
					},
				],
			},
			// list three
			{
				title: 'Hosting Support',
				links: [
					{
						title: 'Manage Your Server',
						link: 'https://cloud.hostjane.com/hosting/login',
					},
					{
						title: 'VPS FAQs',
						link: 'https://cloud.hostjane.com/vps-hosting/#hostjane-faqs',
					},
					{
						title: 'VPS Tutorials',
						link: 'https://help.hostjane.com/vps-hosting/',
					},
				],
			},
			// add more lists if needed
		],
		// column two
		two: [
			// list one
			{
				title: 'Help & Support',
				links: [
					{
						title: 'Help Center',
						link: 'https://help.hostjane.com/',
					},
					{
						title: 'All Categories',
						link: 'https://hostjane.com/marketplace/categories',
					},
					{
						title: 'All Skills',
						link: 'https://www.hostjane.com/marketplace/skills',
					},
					{
						title: 'Contact Support',
						link: 'https://www.hostjane.com/marketplace/contact',
					},
					{
						title: 'Hosting Login',
						link: 'https://cloud.hostjane.com/hosting/login',
					},
					{
						title: 'Community',
						link: '/community',
					},
					{
						title: 'Official Discord',
						link: 'https://discord.gg/5rS6Tvd',
					},
					{
						title: 'WordPress Payments Plugin',
						link: 'https://wordpress.org/plugins/tip-my-work-hostjane-payments/',
					},
					{
						title: 'WordPress Plugin Guide',
						link: 'https://www.hostjane.com/wordpress',
					},
				],
			},
			// list two
			{
				title: 'Legal Agreements',
				links: [
					{
						title: 'General Agreement',
						link: 'https://www.hostjane.com/legal/tos/',
					},
					{
						title: 'Marketplace Rules',
						link: 'https://www.hostjane.com/legal/marketplace-terms/',
					},
					{
						title: 'Hosting Terms',
						link: 'https://www.hostjane.com/legal/hosting-terms/',
					},
					{
						title: 'Privacy Policy',
						link: 'https://www.hostjane.com/legal/privacy/',
					},
					{
						title: 'Acceptable Use Policy',
						link: 'https://www.hostjane.com/legal/use-policy/',
					},
					{
						title: 'Fighting racism',
						link: 'https://www.hostjane.com/legal/ihra-antisemitism/',
					},
					{
						title: 'GDPR',
						link: 'https://www.hostjane.com/legal/gdpr-privacy-notice/',
					},
					{
						title: 'Cookies Policy',
						link: 'https://www.hostjane.com/legal/cookie-policy/',
					},
					{
						title: 'Service Level Agreement',
						link: 'https://www.hostjane.com/legal/sla/',
					},
				],
			},
			// add more lists if needed
		],
		// end column two
		// column three
		three: [
			// list one
			{
				title: 'Sell on HostJane',
				links: [
					{
						title: 'Become a Seller',
						link: 'https://www.hostjane.com/sell',
					},
					{
						title: 'Better Tools',
						link: 'https://help.hostjane.com/marketplace-faq/what-are-boards/',
					},
					{
						title: 'Reporting',
						link: 'https://www.hostjane.com/marketplace/reporting',
					},
					{
						title: 'Order Center',
						link: 'https://www.hostjane.com/marketplace/orders',
					},
					{
						title: 'Create New Listing',
						link: 'https://www.hostjane.com/marketplace/listings/create',
					},
					{
						title: 'Listings Center',
						link: 'https://www.hostjane.com/marketplace/listings',
					},
					{
						title: 'Disputes Center',
						link: 'https://www.hostjane.com/marketplace/disputes',
					},
				],
			},
			// list two
			{
				title: 'About HostJane',
				links: [
					{
						title: 'Jane\'s Mission',
						link: 'https://www.hostjane.com/about',
					},
					{
						title: '#JaneIsPowerful',
						link: 'https://www.hostjane.com/about-jane/',
					},
					{
						title: 'HostJane for Ukraine Album',
						link: 'https://www.hostjane.com/hostjaneforukraine/',
					},
					{
						title: 'Better Work on Medium',
						link: 'https://medium.com/better-work',
					},
					{
						title: 'HostJane on YouTube',
						link: 'https://www.youtube.com/HostJane',
					},
					{
						title: 'HostJane on Facebook',
						link: 'https://www.facebook.com/hostjanecom',
					},
					{
						title: 'HostJane on X',
						link: 'https://x.com/hostjane',
					},
					{
						title: 'HostJane\'s Reddit /r/HostJane',
						link: 'https://www.reddit.com/r/HostJane',
					},
					{
						title: 'HostJane on Instagram',
						link: 'https://www.instagram.com/hostjane/?hl=en',
					},
					{
						title: 'HostJane Skills on LinkedIn',
						link: 'https://linkedin.com/company/hostjane',
					},
					{
						title: 'HostJane Discord Chat',
						link: 'https://discord.gg/5rS6Tvd',
					},
					{
						title: 'TikTok HostJane',
						link: 'https://www.tiktok.com/@hostjane',
					},
					{
						title: 'HostJane Skills on Pinterest',
						link: 'https://www.pinterest.com/hostjane/',
					},
					{
						title: 'HostJane on Snapchat',
						link: 'https://www.snapchat.com/add/hostjane?share_id=RkJFRUVF&locale=en_US',
					},
					{
						title: 'HostJane\'s Telegram channel',
						link: 'https://t.me/hostjanecom',
					},
					{
						title: 'HostJane on Telegram',
						link: 'https://t.me/hostjanesupport',
					},
					{
						title: 'Tumblr HostJane',
						link: 'https://hostjane.tumblr.com',
					},
				],
			},
			// add more lists if needed
		],
		// end column three
	},
};

const sectionTwo = {
	title: 'Web / Mobile / Tech',
	columns: {
		// column one
		one: [
			// list one
			{
				title: 'Highest Demand',
				links: [
					{
						title: 'Web Development',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-development',
					},
					{
						title: 'Web Design / Apps',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/web-design-apps',
					},
					{
						title: 'WordPress',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/wordpress',
					},
					{
						title: 'Blockchain / Crypto / NFTs',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/blockchain-cryptocurrency-nfts',
					},
					{
						title: 'Apps / Mobile',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/apps-mobile',
					},
					{
						title: 'Math / Science Help',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/math-science-algorithms',
					},
					{
						title: 'Programming / Software',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/programming-software',
					},
				],
			},
			// add more lists if needed
		],
		// column two
		two: [
			// list one
			{
				title: 'Featured Services',
				links: [
					{
						title: 'Bug Fixing Services',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/bug-fixing-services',
					},
					{
						title: 'Game Development',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/game-development',
					},
					{
						title: 'Ideas / Help / Consultation',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/ideas-help-consultation',
					},
					{
						title: 'Database Design / Administration',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/database-design-administration',
					},
					{
						title: 'QA / Testing',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/qa-testing',
					},
					{
						title: 'Networking / System Admin',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/networking-system-admin',
					},
					{
						title: 'Cybersecurity / InfoSec',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/information-security',
					},
				],
			},
			// add more lists if needed
		],
		// end column two
		// column three
		three: [
			// list one
			{
				title: 'Key Services',
				links: [
					{
						title: 'ERP / CRM / SCM',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/erp-crm-scm',
					},
					{
						title: 'Management / Training',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/management-training',
					},
					{
						title: 'Telephony / Telecommunications',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/telephony-telecommunications',
					},
					{
						title: 'SAP',
						link: 'https://www.hostjane.com/marketplace/categories/web-mobile-tech-freelancers/sap',
					},	
				],
			},
			// add more lists if needed
		],
		// end column three
	},
};

const sectionThree = {
	title: 'Design / Art / Video / Audio',
	columns: {
		// column one
		one: [
			// list one
			{
				title: 'Highest Demand',
				links: [
					{
						title: 'Graphic Design / Logos',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/graphic-design-logos',
					},
					{
						title: 'Freelance Artists',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/freelance-artists-other-art',
					},
					{
						title: 'Video / Animation',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/video-animation',
					},
					{
						title: 'Illustration',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/illustration',
					},
					{
						title: 'Voice Over / Acting',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/voice-over-acting',
					},
					{
						title: 'Musicians / Singers',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/studio-musicians-session-singers',
					},
					{
						title: 'Book / Magazine Design',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/book-magazine-design',
					},
				],
			},
			// add more lists if needed
		],
		// column two
		two: [
			// list one
			{
				title: 'Featured Services',
				links: [
					{
						title: 'Audio / Sound / Music',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/audio-sound-music',
					},
					{
						title: 'File Conversions',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/file-conversions',
					},
					{
						title: 'Business / Advertising Design',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/business-advertising-design',
					},
					{
						title: 'T-Shirts / Merchandise Design',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/t-shirts-merchandise-design-1',
					},
					{
						title: 'Packaging / Label Design',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/packaging-label-design',
					},
					{
						title: 'CAD / Technical Drawings',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cad-technical-drawings',
					},
					{
						title: 'Image Restoration / Editing',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/image-restoration-editing',
					},
				],
			},
			// add more lists if needed
		],
		// end column two
		// column three
		three: [
			// list one
			{
				title: 'Creative Essentials',
				links: [
					{
						title: 'Christian Services',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography',
					},
					{
						title: 'Photography',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/photography',
					},
					{
						title: 'Printing / Production',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/printing-production',
					},
					{
						title: 'Painting',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/painting',
					},
					{
						title: 'Cartoons / Comic Art',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/cartoons-comic-art',
					},
					{
						title: 'Fashion',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/fashion',
					},
					{
						title: 'Concepts / Direction',
						link: 'https://www.hostjane.com/marketplace/categories/design-art-video-voice/concepts-direction',
					},
				],
			},
			// add more lists if needed
		],
		// end column three
	},
};

const sectionFour = {
	title: 'Bookings',
	columns: {
		// column one
		one: [
			// list one
			{
				title: 'Highest Demand',
				links: [
					{
						title: 'Language Tutors',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/language-tutors',
					},
					{
						title: 'Live Chats',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/live-chats',
					},
					{
						title: 'Online Experiences',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/lifestyle-lessons',
					},
					{
						title: 'ASMR / Therapy',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/personal-development',
					},
					{
						title: 'Test Prep Tutors',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/test-prep-tutors',
					},
					{
						title: 'IT / Computing',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/it-computing',
					},
					{
						title: 'Math Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/math-lessons',
					},
				],
			},
			// add more lists if needed
		],
		// column two
		two: [
			// list one
			{
				title: 'Featured Services',
				links: [
					{
						title: 'Investing / Trading Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/investing-trading-lessons',
					},
					{
						title: 'Music Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/music-lessons',
					},
					{
						title: 'Finance / Accounting Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/finance-accounting-lessons',
					},
					{
						title: 'Online Interpreting Services',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/online-interpreting-services',
					},
					{
						title: 'Business Training',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/business-training',
					},
					{
						title: 'Science Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/science-lessons',
					},
					{
						title: 'English Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/english-lessons',
					},
				],
			},
			// add more lists if needed
		],
		// end column two
		// column three
		three: [
			// list one
			{
				title: 'Other Bookings',
				links: [
					{
						title: 'Creative / Design Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/creative-design-lessons',
					},
					{
						title: 'Teacher Training',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/teacher-training',
					},
					{
						title: 'History Lessons',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/history-lessons',
					},
					{
						title: 'Theology Classes',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/theology',
					},
					{
						title: 'Health / Fitness Teachers',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/health-fitness-teachers',
					},
					{
						title: 'Office Productivity',
						link: 'https://www.hostjane.com/marketplace/categories/online-tutors/office-productivity',
					},
				],
			},
			// add more lists if needed
		],
		// end column three
	},
};

const sectionFive = {
	title: 'Writing / Translation',
	columns: {
		// column one
		one: [
			// list one
			{
				title: 'Highest Demand',
				links: [
					{
						title: 'Translation',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/translation',
					},
					{
						title: 'Creative Writing',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/creative-writing',
					},
					{
						title: 'Book Writing',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/book-writing',
					},
					{
						title: 'General Writing',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/general-writing',
					},
					{
						title: 'Copywriting',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/copywriting',
					},
					{
						title: 'Editing / Proofreading',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/editing-proofreading',
					},
					{
						title: 'Web Content',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/web-content',
					},
				],
			},
			// add more lists if needed
		],
		// column two
		two: [
			// list one
			{
				title: 'Featured Services',
				links: [
					{
						title: 'Article / News Writing',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/article-news-writing',
					},
					{
						title: 'Subtitling',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/subtitling',
					},
					{
						title: 'Researchers',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/research',
					},
					{
						title: 'Writing for Industries',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/writing-for-industries',
					},
					{
						title: 'Scripts / Speeches / Storyboards',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/scripts-speeches-storyboards',
					},
					{
						title: 'Jobs / Résumés',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/jobs-resumes',
					},
					{
						title: 'Review Writing',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/review-writing',
					},
				],
			},
			// add more lists if needed
		],
		// end column two
		// column three
		three: [
			// list one
			{
				title: 'Creative Essentials',
				links: [
					{
						title: 'Christian Worship',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/christian-worship',
					},
					{
						title: 'Grants / Proposals',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/grants-proposals',
					},
					{
						title: 'Songs / Poems',
						link: 'https://www.hostjane.com/marketplace/categories/writing-translation-freelancers/songs-poems',
					},
				],
			},
			// add more lists if needed
		],
		// end column three
	},
};

const sectionSix = {
	title: 'Business / Admin',
	columns: {
		// column one
		one: [
			// list one
			{
				title: 'Highest Demand',
				links: [
					{
						title: 'Digital Marketing',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/digital-marketing',
					},
					{
						title: 'Accounting / Finance / Tax',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/accounting-finance-tax',
					},
					{
						title: 'Business Help / Consulting',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/business-help-consulting',
					},
					{
						title: 'Legal Assistance',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/legal-assistance',
					},
					{
						title: 'Personal / Virtual Assistants',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/personal-virtual-assistants',
					},
					{
						title: 'Transcription Services',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/transcription-services',
					},
					{
						title: 'Sales / General Marketing',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/sales-general-marketing',
					},
				],
			},
			// add more lists if needed
		],
		// column two
		two: [
			// list one
			{
				title: 'Featured Services',
				links: [
					{
						title: 'Business Intelligence',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/business-intelligence',
					},
					{
						title: 'Telemarketing / Remote Selling',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/call-centers-telemarketing',
					},
					{
						title: 'Press Release Writing',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/press-release-writing',
					},
					{
						title: 'Data Entry / Micro Jobs',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/data-entry',
					},
					{
						title: 'Spreadsheets / Excel / Office',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/spreadsheets-excel-microsoft-office',
					},
					{
						title: 'Customer Service',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/customer-service',
					},
					{
						title: 'Word Processing / Typing',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/word-processing-typing',
					},
				],
			},
			// add more lists if needed
		],
		// end column two
		// column three
		three: [
			// list one
			{
				title: 'Key Services',
				links: [
					{
						title: 'Mailings / Lists',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/mailings-lists',
					},
					{
						title: 'Human Resources (HR)',
						link: 'https://www.hostjane.com/marketplace/categories/business-admin-support/human-resources-hr',
					},
				],
			},
			// add more lists if needed
		],
		// end column three
	},
};

export default {
	pageTitle: 'HostJane Site Map',
	seoDescription: 'Sitemap for HostJane\'s hosting and skills marketplace giving buyers and sellers organized access to the platform.',
	sections: [
		// section one
		sectionOne,
		// section two
		sectionTwo,
		// section three
		sectionThree,
		// section four
		sectionFour,
		// section five
		sectionFive,
		// section six
		sectionSix,
	],
};
