/* eslint-disable react/no-array-index-key */
import React from 'react';
import styled from 'styled-components';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import MainNavigation from '../../components/MainNavigation';
import MainFooter from '../../components/MainFooter';
import { Wrapper, Heading } from '../../components/Home/common';
import SiteContainer from '../../components/SiteContainer';

import data from '../../../site-data/sitemap';

const PageWrapper = styled(Wrapper)`
	&&&& {
		padding-top: 0;
		padding-bottom: 0;
	}
	${SiteContainer} {
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.tablet}px) {
			padding: 10px 20px 0;
		}
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			padding: 0 40px;
		}
	}
`;

const SectionColumnListUl = styled.ul`
	list-style: none;
	margin: 0 0 26px 0;
	padding: 0;
	font-family: 'SF Pro Text', 'SF Pro Icons', 'Helvetica Neue', 'Helvetica',
		'Arial', sans-serif;

	li {
		padding: 0 40px 7px 0;
		font-size: 17px;
		color: #111;
		line-height: 1.47059;

		&.hostjane-sitemap-list__heading {
			font-weight: 500;
			letter-spacing: -0.022em;
		}
	}
	a {
		color: #0070c9;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
			color: #0070c9;
		}
	}
`;
function SectionColumnList({ list }) {
	return (
		<SectionColumnListUl className="hostjane-sitemap-list">
			<li className="hostjane-sitemap-list__heading">{list.title}</li>
			{list.links.map((link, index) => (
				<li className="hostjane-sitemap-list__link" key={index}>
					<a
						href={link.link}
						className="hostjane-sitemap-list__anchor"
					>
						{link.title}
					</a>
				</li>
			))}
		</SectionColumnListUl>
	);
}

const SectionColumnWrapper = styled.div`
	flex: 0 0 100%;
	padding: 0;
	@media screen and (min-width: ${props =>
			props.theme.siteApp.breakpoints.tablet}px) {
		flex-basis: 33.33%;
	}
`;
function SectionColumn({ lists }) {
	return (
		<SectionColumnWrapper>
			{lists.map((list, index) => (
				<SectionColumnList key={index} list={list} />
			))}
		</SectionColumnWrapper>
	);
}

const SectionWrapper = styled.div`
	margin: 0 0 35px 0;
	.hostjane-sitemap-section__heading {
		color: #111;
		font-size: 24px;
		line-height: 1.16667;
		font-weight: 600;
		letter-spacing: 0.009em;
		border-top: 1px solid #d6d6d6;
		display: block;
		padding: 30px 0 30px 0;
		margin: 0;
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.tablet}px) {
			font-size: 28px;
			line-height: 1.14286;
			font-weight: 600;
			letter-spacing: 0.007em;
		}
		@media screen and (min-width: ${props =>
				props.theme.siteApp.breakpoints.desktop}px) {
			font-size: 32px;
			line-height: 1.125;
			font-weight: 600;
			letter-spacing: 0.004em;
		}
	}
	&:first-child {
		.hostjane-sitemap-section__heading {
			border-top: 0 none;
			border-bottom: 1px solid #d6d6d6;
			padding-top: 10px;
			padding-bottom: 20px;
			margin-bottom: 45px;
			@media screen and (min-width: ${props =>
					props.theme.siteApp.breakpoints.tablet}px) {
				padding-top: 0;
				margin-bottom: 65px;
			}
		}
	}

	.hostjane-sitemap-section__columns {
		display: flex;
		flex-flow: row wrap;
	}
`;
function Section({ section }) {
	return (
		<SectionWrapper className="hostjane-sitemap-section">
			<h3 className="hostjane-sitemap-section__heading">
				{section.title}
			</h3>
			<div className="hostjane-sitemap-section__columns">
				{Object.keys(section.columns).map(col => (
					<SectionColumn key={col} lists={section.columns[col]} />
				))}
			</div>
		</SectionWrapper>
	);
}

function SitemapPage(props) {
	return (
		<Layout {...props} hasFooter={false} fullWidth>
			<SEO title={data.pageTitle} description={data.seoDescription} />
			<MainNavigation />
			<PageWrapper className="hostjane-sitemap">
				<SiteContainer>
					{data.sections.map((section, index) => (
						<Section key={index} section={section} />
					))}
				</SiteContainer>
			</PageWrapper>
			<MainFooter />
		</Layout>
	);
}

export default SitemapPage;
